import { useEffect, useMemo, useState } from 'react';
import Button from '../../components/button/Button';
import InputField from '../../components/inputField/InputField';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { SignupAPI } from '../../logic/signupApi/signupApi';
import MessageBubble from '../../components/messageBubble/MessageBubble';
import Captcha from '../../components/captcha/Captcha';
import ErrorMessage from '../../components/errorMessage/ErrorMessage';
import Modal from '../../components/modal/Modal';
import ScrollBox from '../../components/scrollBox/ScrollBox';
import Checkbox from '../../components/checkbox/Checkbox';
import Loader from '../../components/loader/Loader';
import { getCountry } from '../../utils/phoneNumberUtils';
import platform from 'platform';
import { useTranslation } from 'react-i18next';

type CodeTypes = 'community_link' | 'community_typed' | 'user_link' | 'user_typed' | null;

interface RegistrationScreenProps {
  communityName: string | null;
  setCommunityName: React.Dispatch<React.SetStateAction<string | null>>;
  setCommunityLogo: React.Dispatch<React.SetStateAction<string | null>>;
}

const RegistrationScreen = ({ communityName, setCommunityName, setCommunityLogo }: RegistrationScreenProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors, defaultValues }, watch } = useForm();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipAppearTime = 15;

  const [refresh, doRefresh] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState('');

  const [linkRef, setLinkRef] = useState<string | null>(null);

  const [captcha, setCaptcha] = useState<string | null>(null);

  const [linkedCode, setLinkedCode] = useState<string | null>(null);
  
  const [cTypeState, setCType] = useState<CodeTypes>(null);
  const [associationToCommunityState, setAssociationToCommunity] = useState<string>('Member does not claim to belong to community');
  const [isWrongCodeState, setIsWrongCode] = useState<boolean>(false);
  
  const [proceedWithError, setProceedWithError] = useState<boolean>(false);

  const [country, setCountry] = useState("");

  const [errMsgVisible, setErrMsgVisible] = useState(false);
  const [errorMode, setErrorMode] = useState<'warning' | 'error'>('error');
  const [errMsgTxt, setErrMsgTxt] = useState('');

  const [isWaiverModalOpened, setIsWaiverModalOpened] = useState(false);
  const [isWaiverAccepted, setIsWaiverAccepted] = useState(false);

  const email = useLocation().state?.email || null;
  const phone = useLocation().state?.phone || null;
  
  const [isLoading, setIsLoading] = useState(false);

  const referralCode = watch('referral');

  useEffect(() => {
    checkReferralId();
    
    const country = getCountry();
    country.then((response) => {
      setCountry(response);
    });
  }, []);
  
  useEffect(() => {
    setPhoneNumber(phone);
  }, [phone]);

  useEffect(() => {
    if(isWrongCodeState) {
      setProceedWithError(false);
      setErrMsgVisible(false);
      setIsWrongCode(false);
      setLinkedCode(null);
      if(referralCode === '') {
        setAssociationToCommunity('Member does not claim to belong to community');
      } 
    } 
  }, [referralCode]);
  
  //TODO: uncomment when we know what to do with this tooltip
  // useEffect(()=>{
  //   if(timer<tooltipAppearTime){
  //     setTimeout(()=>{
  //       setTimer(timer+1);
  //     }, 1000);
  //   }
  //   if(timer===tooltipAppearTime){
  //     setIsTooltipVisible(true);
  //   }
  // },[timer]);

  const checkReferralId = async () => {
    let url = new URL(window.location.href);

    const code = url.searchParams.get('ref');

    if(code) {
      setLinkRef(code);
      assignCode(atob(code));
    }
  }

  const assignCode = async (code: string, isLink: boolean = true) => {

    return await SignupAPI.checkIfInviteCodeIsValid(code, isLink).then((result) => {
      console.log("Refereal community:", result);

      const associationToCommunity = `Member with an invite code for: ${result.communityName}, ${code}`;
      
      setLinkedCode(code);
      setCommunityName(result.communityName);
      setCommunityLogo(result.communityLogo)
      setCType(result.cType);
      setIsWrongCode(false);
      setAssociationToCommunity(associationToCommunity);
      setErrMsgVisible(false);

      if(isLink){
        return;
      }

      const cType = result.cType;
      return { cType, associationToCommunity, isWrongCode: false };

    }).catch((error: { message: string, code: 'community_forbidden' | 'code_invalid', communityName: string | null, defaultCommunityName: string }) => {
      console.log('error while checking invite code: ', error);

      let associationToCommunity = `Member tried using non-existing referral code: ${code}`;

      setErrorMode('warning');

      switch(error.code) {
        // we don't care about that case but I'm leaving it just in case...
        case 'community_forbidden':
          setErrMsgVisible(true);
          setErrMsgTxt(`${error.communityName} does not allow member invites. You will be referred to ${error.defaultCommunityName} instead. If you think this is a mistake please contact support.`);
          associationToCommunity = `Member tried using community forbidden referral code ${code}`
          break;
        case 'code_invalid':
          setErrMsgVisible(true);
          setErrMsgTxt(`Invalid code: ${code}. You will be referred to ${error.defaultCommunityName} instead. If you think this is a mistake please contact support.`);
          break;
        default: 
          setErrMsgVisible(true);
          setErrMsgTxt('Something went wrong with referral code. Please try again.');
          break;
      }

      setAssociationToCommunity(associationToCommunity);
      setIsWrongCode(true);
      setProceedWithError(true);
      setLinkedCode(code);

      if(isLink) {
        return;
      }

      return { cType: null, associationToCommunity, isWrongCode: true }

    });
  }

  const onSubmit = async (data: any) => {
    
    setIsLoading(true);

    let phone = '';
    if(phoneNumber){
      phone = `+${phoneNumber.trim()}`
    }
    let email = data.email.trim();
    let fullName = data.fullName.trim();
    let typedCode = data.referral.trim();

    let associationToCommunity = associationToCommunityState;
    let cType = cTypeState;
    let isWrongCode = isWrongCodeState;

    if(typedCode) {
      const params = await assignCode(typedCode, false);
      if(params) {
        associationToCommunity = params.associationToCommunity;
        cType = params.cType;
        isWrongCode = params.isWrongCode;
      }
    }

    if(isWrongCode && !proceedWithError) {
      setIsLoading(false);
      return;
    }

    const deviceInfo = `${platform.name} ${platform.version} on ${platform.os?.family} ${platform.os?.version ? platform.os?.version+' ' : ''}${platform.os?.architecture}`;

    await SignupAPI.validateAndRegisterParticipant({
      recaptchaToken: captcha,
      fullName: fullName,
      email: email,
      telephoneMobile: phone,
      inviteCode: typedCode || linkedCode || null,
      cType: cType,
      associationToCommunity: associationToCommunity,
      requestURL: window.location.hostname,
      registrationType: 'direct',
      deviceInfo: deviceInfo,
      // reference: If they want to have a text "How did you heard about Televeda?" or something..."
    }).then(async () => {
      return await SignupAPI.sendLoginCode({
        whereToSendTheCode: 'email',
        email: email
      }).catch((error: unknown) => {
        setErrMsgTxt("Can not send verifiction code.");
        setErrorMode('error');
        setErrMsgVisible(true);
        console.log('error while sending login code: ', error)
      })
    }).then((response: any) => {
      if(response.result === 'ok') {
        navigate("/login/verification", {state: {email: email, phone}});
      }
    }).catch((error: any) => {
      console.log('error', error);

      if( error.opCode ) {

        switch (error.opCode) {
          case 'reg/email-taken':
            console.log("Navigate to login with email: ", email);
            navigate(`/login${linkRef ? `?ref=${linkRef}`: ''}`, {state: {email: email, phone}});
            break;

          case 'reg/phone-taken':
            console.log("Navigate to login with phone: ", phone);
            navigate(`/login/phone${linkRef ? `?ref=${linkRef}`: ''}`, {state: {email: email, phone: phoneNumber.trim()}});
            break;
          
          case 'reg/invalid-name':
            setErrMsgTxt("Invalid name - must be at least 3 characters.");
            setErrorMode('error');
            setErrMsgVisible(true);
            break;
        
          default:
            let message = error.message;
      
            switch (message) {
              case 'TOO_LONG':
                message =
                  'You have entered too many digits in the phone field. Please review your input';
                break;
              case 'TOO_SHORT':
                message =
                  'You have entered not enough digits in the phone field. Please review your input';
                break;
              case 'INVALID_LENGTH':
              case 'Invalid format.':
                message =
                  'It seems like the entered phone is invalid. Please review your input';
                break;
              default:
                break;
            }

            setErrMsgTxt(message);
            setErrorMode('error');
            setErrMsgVisible(true);
        }
      }
      else {
        setErrMsgTxt(error.message);
        setErrorMode('error');
        setErrMsgVisible(true);
      }

      setIsLoading(false);
      doRefresh(prev => prev + 1);
    });
  };

  const captchaOnChange = (value: string | null) => {
    setCaptcha(value);
  }

  const removeMessageBubble = () => {
    setIsTooltipVisible(false);
  }

  return (
    <div className='body-container'>
        <div className='content-container'>

            {
              errMsgVisible && <ErrorMessage message={errMsgTxt} onClose={()=> {setErrMsgVisible(false);}} mode={errorMode}/> 
            }
            
            <div className='text-container'>
                <text className='text-big'>{t('NEW_MEMBER_REGISTRATION')}</text>
                <text className='text-big'>{t('WELCOME')}</text>
            </div>
            {isWaiverModalOpened && 
            <Modal>
              <>
              <div className='text-container'>
                <text className='text-big'>{t('WAIVER_OF_LIABILITY')}</text>
                <text className='text-medium'>{t('REGISTRATION_SCREEN_TEXT_FIRST')}</text>
              </div>
              <ScrollBox children={
                <>
                  <text className='text-small-bold'>{t('REGISTRATION_SCREEN_TEXT_SECOND')}
                  </text>
                  &nbsp;
                  <text className='text-small'>
                  {t('REGISTRATION_SCREEN_TEXT_THIRD')}
                  {t('REGISTRATION_SCREEN_TEXT_FOURTH')}
                  </text>
                </>}
              />
              <Button title={t('CONTINUE')} onClick={() => {
                setIsWaiverModalOpened(false)
                setIsWaiverAccepted(true);
                }}/> 
            </>
            </Modal>}
            <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
              {
                communityName && 
                <text style={{marginTop: 15, marginBottom: 15}}>
                  <b> {t('REGISTARTION_SCREEN_TEXT_FIFTH')}</b> <span>{communityName}</span>.
                </text>
              }

              <InputField 
                name="fullName" 
                errors={errors} 
                register={register} 
                options={ {required: true,
                  pattern: {
                    value: /^\s*[A-Za-z/./]+(\s+[A-Za-z/./]+)*\s*$/,
                    message: t('INVALID_FULL_NAME')
                  },
                  validate: {
                    minLength: (v) => v.length >= 0,
                }}}
                placeholder={`${t('FULL_NAME')} *`}
              />
              <InputField 
                name="email" 
                errors={errors} 
                register={register} 
                options={ {required: true,
                  pattern: {
                    value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
                    message: t('INVALID_EMAIL')
                  },
                  validate: {
                    minLength: (v) => v.length >= 0,
                }}} 
                defaultValue={email}
                placeholder={`${t('EMAIL_ADDRESS')} *`}
                aria-label='email'
              />
              {/* <PhoneInput country={country ? country : ''} value={phoneNumber} onChange={(phone)=> setPhoneNumber(phone)}/> */}
              {
                // If country is assigned and there is a phone value in the field the value is removed for some reason
                phone ?
                // if we come to this screen from the registration with a phone number,
                // the country is automatically detected from the phone number,
                // so we can bypass the bug by not fetching the country
                <PhoneInput 
                  value={phoneNumber}
                  inputClass={phoneNumber ? 'phone-input-bold' : 'phone-input-regular'}
                  buttonClass={'phone-button'}
                  onChange={(phone) => {
                  setPhoneNumber(phone)
                }}/>
                :
                // if we come here without a phone number to display,
                // we fetch and assign the country
                // and since there is no value that is going to disappear its okay
                <PhoneInput 
                  country={country}
                  inputClass={phoneNumber ? 'phone-input-bold' : 'phone-input-regular'}
                  buttonClass={'phone-button'}
                  value={phoneNumber}
                  onChange={(phone) => {
                  setPhoneNumber(phone)
                }}/>
              }
              {!communityName && <InputField 
                name="referral" 
                errors={errors} 
                register={register}
                placeholder={t('REFERAL_CODE_OPTIONAL')} isOptional={true}
              />}
              <div style={{marginLeft: 8}}>
                <Checkbox
                  name='acceptWaiver'
                  register={register}
                  errors={errors} 
                  options={ {required: true}} 
                  value={isWaiverAccepted} 
                  onChange={()=> setIsWaiverAccepted(!isWaiverAccepted)}
                >
                  <text className='text-link' onClick={()=> setIsWaiverModalOpened(true)}>{t('REGISTRATION_TEXT_SIXTH')}</text>
                </Checkbox>
              </div>  
              <Captcha onChange={captchaOnChange} refresh={refresh}/>
              {isLoading && <Loader/>}
              <Button disabled={isLoading} title={isWrongCodeState ? t('CONTINUE_ANYWAY') : t('CONTINUE')}/> 
            </form>
          </div>
        <ProgressBar numberOfSteps={2} currentStep={1}/>
        {isTooltipVisible && <MessageBubble removeBubble={removeMessageBubble} title={t('NEED_HELP')} content={t('REGISTRATION_SCREEN_TEXT_SEVENTH')}/>}
        <text className='text-link' onClick={() => navigate(`/login${linkRef ? `?ref=${linkRef}`: ''}`)}>{t('LOGIN_INSTEAD')}</text>
    </div>
  )
};

export default RegistrationScreen